// 子路由要渲染到父路由的<router-view />中
const RouteView = {
  name: "RouteView",
  render: (h) => h("router-view"),
};
// 定义页面路由
// 现场管理系统
const constantRouterComponents = {
  // 登陆
  Login: () => import("@/views/login"),
  // 工地管理
  BasicLayout: () => import("@/layouts/BasicLayout"),
  SiteMap: () => import("@/views/site/SiteMap.vue"), // 施工地图
  CustomMap: () => import("@/views/site/CustomMap.vue"), //定制地图
  SiteList: () => import("@/views/site/SiteList.vue"), // 工地列表
  SiteSuspend: () => import("@/views/site/SiteSuspend.vue"), // 暂停施工
  SitePreview: () => import("@/views/site/SitePreview.vue"), // 直播
  SiteSnapshot: () => import("@/views/site/SiteSnapshot.vue"), // 快照
  SitePlayback: () => import("@/views/site/SitePlayback.vue"), // 回放
  SiteAttend: () => import("@/views/site/SiteAttend.vue"), // 考勤
  // 员工管理
  WorkerList: () => import("@/views/worker/WorkerList.vue"), // 人员列表
  WorkerAttend: () => import("@/views/worker/WorkerAttend.vue"), // 人员考勤
  WorkerAttendStatistics: () =>
    import("@/views/worker/WorkerAttendStatistics.vue"), // 人员考勤统计
  // 设备管理
  DeviceList: () => import("@/views/device/DeviceList.vue"), // 设备列表
  DeviceStock: () => import("@/views/device/DeviceStock.vue"), // 设备导入
  DeviceStatistics: () => import("@/views/device/DeviceStatistics.vue"), //设备统计
  // 工地形象
  AlarmEvent: () => import("@/views/image/AlarmEvent.vue"), // 报警事件
  SiteNeat: () => import("@/views/image/SiteNeat.vue"), // 整洁管理
  CorrectionLog: () => import("@/views/image/CorrectionLog.vue"), // 纠正日志
  // 权限管理
  RoleList: () => import("@/views/role/RoleList.vue"), // 角色权限
  UserList: () => import("@/views/role/UserList.vue"), // 用户权限
  Organization: () => import("@/views/role/OrganizatAdmin.vue"), //组织管理
  PersonalizedView: () => import("@/views/role/PersonalizedView.vue"), //个性化设置
  MenuList: () => import("@/views/role/MenuList.vue"), //页面设置
  // 库存与订单管理
  // DeviceStockList: () => import("@/views/stockAndOrder/deviceStockList.vue"), // 库存管理
  // 日报
  DailyList: () => import("@/views/site/daily/DailyList.vue"),
  SampleSite: () => import("@/views/site/SampleSite.vue"),
  // ExhibitionBboard: () => import("@views/exhibitionboard/ExhibitionBoard.vue"), //展厅看板
  // 数据统计
  DailySheet: () => import("@/views/dataStatistics/DailySheet.vue"),
  SiteImage: () => import("@/views/dataStatistics/SiteImage.vue"), //工地形象统计
  AttendStatistics: () => import("@/views/dataStatistics/AttendStatistics.vue"), //考勤统计
  HomeMaster: () => import("@/views/home/HomeMaster.vue"), //我要掌握

  // 工人维度
  WorkerInfo: () => import("@/views/home/worker/WorkerInfo.vue"), //工人基本信息
  ProjectExperience: () => import("@/views/home/worker/ProjectExperience.vue"), //工人项目经历
  EvaluationRecord: () => import("@/views/home/worker/EvaluationRecord.vue"), //工人评价记录
  OrderRank: () => import("@/views/home/worker/OrderRank.vue"), //工人等级/勋章
  BreakRecord: () => import("@/views/home/worker/BreakRecord.vue"), //工人违章记录
  BecomplainedRecord: () =>
    import("@/views/home/worker/BecomplainedRecord.vue"), //工人被投诉记录
  CleanChange: () => import("@/views/home/worker/CleanChange.vue"), //工人施工工地整洁度变化
  AcceptanceRecord: () => import("@/views/home/worker/AcceptanceRecord.vue"), //工人开工完工验收记录
  VisitorVisit: () => import("@/views/home/visitor/VisitorVisit.vue"), //陌生访客到访情况

  // 工地维度
  SiteInfo: () => import("@/views/home/site/SiteInfo.vue"), //工地维度-基本信息
  SiteDuration: () => import("@/views/home/site/SiteDuration.vue"), //工地维度-工期信息
  SiteAttendance: () => import("@/views/home/site/SiteAttendance.vue"), //工地维度-施工工人考勤信息
  SitePatrol: () => import("@/views/home/site/SitePatrol.vue"), //工地维度-监管人员巡查信息
  SiteStylistdirect: () => import("@/views/home/site/SiteStylistdirect.vue"), //工地维度-设计人员现场指导信息
  SiteAccept: () => import("@/views/home/site/SiteAccept.vue"), //工地维度-现场验收信息
  SiteRequiredpatrol: () => import("@/views/home/site/SiteRequiredpatrol.vue"), //工地维度-按照规定巡查
  SiteRequireaccept: () => import("@/views/home/site/SiteRequireaccept.vue"), //工地维度-按照规定现场验收
  SiteRemotepatrol: () => import("@/views/home/site/SiteRemotepatrol.vue"), //工地维度-远程巡查信息
  SiteVidicon: () => import("@/views/home/site/SiteVidicon.vue"), //工地维度-工地摄像机在线分析
  SiteBreak: () => import("@/views/home/site/SiteBreak.vue"), //工地维度-工地违章信息
  SiteClean: () => import("@/views/home/site/SiteClean.vue"), //工地维度-工地整洁度变化趋势
  SiteComplaint: () => import("@/views/home/site/SiteComplaint.vue"), //工地维度-项目投诉记录

  // 业主维度
  OwnerInfo: () => import("@/views/home/owner/OwnerInfo.vue"), //业主维度-业主资料
  OwnerVideoaccess: () => import("@/views/home/owner/OwnerVideoaccess.vue"), //业主维度-视频访问记录
  OwnerVisit: () => import("@/views/home/owner/OwnerVisit.vue"), //业主维度-现场到访记录
  OwnerInteract: () => import("@/views/home/owner/OwnerInteract.vue"), //业主维度-业主互动记录
  OwnerFeedback: () => import("@/views/home/owner/OwnerFeedback.vue"), //业主维度-业主信息反馈(含投诉)
  OwnerPromise: () => import("@/views/home/owner/OwnerPromise.vue"), //业主维度-业主承诺管理
  OwnerProjectwarn: () => import("@/views/home/owner/OwnerProjectwarn.vue"), //业主维度-业主项目工期预警记录
  OwnerQualitywarn: () => import("@/views/home/owner/OwnerQualitywarn.vue"), //业主维度-施工质量预警
  OwnerWarnrecord: () => import("@/views/home/owner/OwnerWarnrecord.vue"), //业主维度-工地报警记录

  // 管理维度
  ManageSupervise: () => import("@/views/home/manage/ManageSupervise.vue"), //管理维度-监管人员督察
  ManageUnit: () => import("@/views/home/manage/ManageUnit.vue"), //管理维度-管理下基层
  ManageInspect: () => import("@/views/home/manage/ManageInspect.vue"), //管理维度-规定动作核查
  ManageComplaint: () => import("@/views/home/manage/ManageComplaint.vue"), //管理维度-业主反馈/投诉
  ManageFindproblem: () => import("@/views/home/manage/ManageFindproblem.vue"), //管理维度-发现问题
  ManageRework: () => import("@/views/home/manage/ManageRework.vue"), //管理维度-返工分析
  ManagePostpone: () => import("@/views/home/manage/ManagePostpone.vue"), //管理维度-延期分析
  ManageQuality: () => import("@/views/home/manage/ManageQuality.vue"), //管理维度-质量分析
};
// 交付系统
const constantRouterComponentsJF = {
  // 模板管理
  MaterialCategories: () =>
    import("@/viewsjf/template/materialcategories/MaterialCategories.vue"), //材料类别
  MaterialBrand: () =>
    import("@/viewsjf/template/materialbrand/MaterialBrand.vue"), //材料品牌
  MaterialInfo: () =>
    import("@/viewsjf/template/materialinfo/MaterialInfo.vue"), //材料信息
  ConstructProcedure: () =>
    import("@/viewsjf/template/constructprocedure/ConstructProcedure.vue"), //施工工序
  ConstructType: () =>
    import("@/viewsjf/template/constructtype/ConstructType.vue"), //施工项类型
  ConstructionProject: () =>
    import("@/viewsjf/template/constructionproject/ConstructionProject.vue"),
  PackageType: () => import("@/viewsjf/template/packagetype/PackageType.vue"), //套餐类别
  ExtraCharge: () => import("@/viewsjf/template/extracharge/ExtraCharge.vue"), //额外收费项
  PackageTemplate: () => import("@/viewsjf/package/PackageTemplate.vue"), //套餐模版列表
  BuildPackage: () => import("@/viewsjf/package/BuildPackage.vue"), //新建套餐
  QuotationList: () => import("@/viewsjf/quotation/QuotationList.vue"), //报价列表
  BuildQuotation: () => import("@/viewsjf/quotation/BuildQuotation.vue"), //新建报价
  ScheduleList: () => import("@/viewsjf/schedule/ScheduleList.vue"), //排期列表
  BuildSchedule: () => import("@/viewsjf/schedule/BuildSchedule.vue"), //新建排期
  UndispatchedOrder: () =>
    import("@/viewsjf/order/undispatchedorder/UndispatchedOrder.vue"), //派单管理--未派单
  OrderSent: () => import("@/viewsjf/order/ordersent/OrderSent.vue"), //派单管理--已派单
  WaitingOrder: () =>
    import("@/viewsjf/material/waitingorder/WaitingOrder.vue"), //物料管理--待下单
  OrderMaterials: () =>
    import("@/viewsjf/material/waitingorder/OrderMaterials.vue"), //物料管理--确认下单物料
  AlreadyOrder: () =>
    import("@/viewsjf/material/alreadyorder/AlreadyOrder.vue"), //物料管理--已下单
  OrderDetails: () =>
    import("@/viewsjf/material/alreadyorder/OrderDetails.vue"), //物料管理--已下单--详情
  TakeDelivery: () =>
    import("@/viewsjf/material/takedelivery/TakeDelivery.vue"), //物料管理--收货情况
  ReceiptDetails: () =>
    import("@/viewsjf/material/takedelivery/ReceiptDetails.vue"), //物料管理--收货情况--订单详情
  ChangeGoods: () => import("@/viewsjf/material/changegoods/ChangeGoods.vue"), //物料管理--退换货
  ChangeGoodsDetails: () =>
    import("@/viewsjf/material/changegoods/ChangeGoodsDetails.vue"), //物料管理--退换货详情
};
export const asyncRouterMap = [
  // 首页
  {
    path: "/Home",
    name: "Home",
    meta: { title: "首页", icon: "team" },
    component: RouteView,
    // component: constantRouterComponents.HomeMaster,
    // component: () => import("@/views/home/home.vue"),
    children: [
      {
        path: "/HomeMaster",
        name: "HomeMaster",
        meta: { title: "首页", showMenu: false, memoryRouter: false },
        component: constantRouterComponents.HomeMaster,
      },
      {
        path: "/WorkerInfo",
        name: "WorkerInfo",
        meta: {
          title: "工人基本信息",
          showMenu: false,
          memoryRouter: false, //是否记忆当前路由为常用工具
        },
        component: constantRouterComponents.WorkerInfo,
      },
      {
        path: "/ProjectExperience",
        name: "ProjectExperience",
        meta: {
          title: "项目经历",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ProjectExperience,
      },
      {
        path: "/EvaluationRecord",
        name: "EvaluationRecord",
        meta: {
          title: "评价记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.EvaluationRecord,
      },
      {
        path: "/OrderRank",
        name: "OrderRank",
        meta: {
          title: "等级/勋章",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OrderRank,
      },
      {
        path: "/BreakRecord",
        name: "BreakRecord",
        meta: {
          title: "违章记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.BreakRecord,
      },
      {
        path: "/BecomplainedRecord",
        name: "BecomplainedRecord",
        meta: {
          title: "被投诉记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.BecomplainedRecord,
      },
      {
        path: "/CleanChange",
        name: "CleanChange",
        meta: {
          title: "施工工地整洁度变化",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.CleanChange,
      },
      {
        path: "/AcceptanceRecord",
        name: "AcceptanceRecord",
        meta: {
          title: "开工完工验收记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.AcceptanceRecord,
      },
      {
        path: "/VisitorVisit",
        name: "VisitorVisit",
        meta: {
          title: "陌生访客到访情况",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.VisitorVisit,
      },
      {
        path: "/SiteInfo",
        name: "SiteInfo",
        meta: {
          title: "基本信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteInfo,
      },
      {
        path: "/SiteDuration",
        name: "SiteDuration",
        meta: {
          title: "工期信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteDuration,
      },
      {
        path: "/SiteAttendance",
        name: "SiteAttendance",
        meta: {
          title: "施工工人考勤信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteAttendance,
      },
      {
        path: "/SitePatrol",
        name: "SitePatrol",
        meta: {
          title: "监管人员巡查信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SitePatrol,
      },
      {
        path: "/SiteStylistdirect",
        name: "SiteStylistdirect",
        meta: {
          title: "设计人员现场指导信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteStylistdirect,
      },
      {
        path: "/SiteAccept",
        name: "SiteAccept",
        meta: {
          title: "现场验收信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteAccept,
      },
      {
        path: "/SiteRequiredpatrol",
        name: "SiteRequiredpatrol",
        meta: {
          title: "按照规定巡查",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteRequiredpatrol,
      },
      {
        path: "/SiteRequireaccept",
        name: "SiteRequireaccept",
        meta: {
          title: "按照规定现场验收",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteRequireaccept,
      },
      {
        path: "/SiteRemotepatrol",
        name: "SiteRemotepatrol",
        meta: {
          title: "远程巡查信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteRemotepatrol,
      },
      {
        path: "/SiteVidicon",
        name: "SiteVidicon",
        meta: {
          title: "工地摄像机在线分析",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteVidicon,
      },
      {
        path: "/SiteBreak",
        name: "SiteBreak",
        meta: {
          title: "工地违章信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteBreak,
      },
      {
        path: "/SiteClean",
        name: "SiteClean",
        meta: {
          title: "工地整洁度变化趋势",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteClean,
      },
      {
        path: "/SiteComplaint",
        name: "SiteComplaint",
        meta: {
          title: "项目投诉记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.SiteComplaint,
      },
      {
        path: "/OwnerInfo",
        name: "OwnerInfo",
        meta: {
          title: "业主资料",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerInfo,
      },
      {
        path: "/OwnerVideoaccess",
        name: "OwnerVideoaccess",
        meta: {
          title: "视频访问记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerVideoaccess,
      },
      {
        path: "/OwnerVisit",
        name: "OwnerVisit",
        meta: {
          title: "现场到访记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerVisit,
      },
      {
        path: "/OwnerInteract",
        name: "OwnerInteract",
        meta: {
          title: "业主互动记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerInteract,
      },
      {
        path: "/OwnerFeedback",
        name: "OwnerFeedback",
        meta: {
          title: "业主信息反馈(含投诉)",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerFeedback,
      },
      {
        path: "/OwnerPromise",
        name: "OwnerPromise",
        meta: {
          title: "业主承诺管理",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerPromise,
      },
      {
        path: "/OwnerProjectwarn",
        name: "OwnerProjectwarn",
        meta: {
          title: "业主项目工期预警记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerProjectwarn,
      },
      {
        path: "/OwnerQualitywarn",
        name: "OwnerQualitywarn",
        meta: {
          title: "施工质量预警",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerQualitywarn,
      },
      {
        path: "/OwnerWarnrecord",
        name: "OwnerWarnrecord",
        meta: {
          title: "工地报警记录",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.OwnerWarnrecord,
      },
      // 管理维度
      {
        path: "/ManageSupervise",
        name: "ManageSupervise",
        meta: {
          title: "监管人员督察",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageSupervise,
      },
      {
        path: "/ManageUnit",
        name: "ManageUnit",
        meta: {
          title: "管理下基层",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageUnit,
      },
      {
        path: "/ManageInspect",
        name: "ManageInspect",
        meta: {
          title: "规定动作核查",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageInspect,
      },
      {
        path: "/ManageComplaint",
        name: "ManageComplaint",
        meta: {
          title: "业主反馈/投诉",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageComplaint,
      },
      {
        path: "/ManageFindproblem",
        name: "ManageFindproblem",
        meta: {
          title: "发现问题",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageFindproblem,
      },
      {
        path: "/ManageRework",
        name: "ManageRework",
        meta: {
          title: "返工分析",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageRework,
      },
      {
        path: "/ManagePostpone",
        name: "ManagePostpone",
        meta: {
          title: "延期分析",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManagePostpone,
      },
      {
        path: "/ManageQuality",
        name: "ManageQuality",
        meta: {
          title: "质量分析",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponents.ManageQuality,
      },
    ],
  },
  // 数据看板
  {
    path: "/data",
    name: "data",
    meta: { title: "数据看板", icon: "fund" },
    // component: RouteView,
    component: () => import("@/views/data/DailyMonitor.vue"),
    children: [
      {
        path: "/DailyMonitor",
        name: "DailyMonitor",
        meta: { title: "数据看板", showMenu: false },
        component: () => import("@/views/data/DailyMonitor.vue"),
      },
      {
        path: "/DataV",
        name: "DataV",
        // meta: { title: "AI数据可视化看板", showMenu: true },
        meta: { title: "AI可视化看板", showMenu: false },
        component: () => import("@/views/datav/index.vue"),
      },
      {
        path: "/Patrol",
        name: "Patrol",
        // meta: { title: "现场可视化看板-巡查", showMenu: true },
        meta: { title: "工地巡查大屏", showMenu: false },
        component: () => import("@/views/inspection/index.vue"),
      },
      // {
      //   path: "/Inspect",
      //   name: "Inspect",
      //   meta: { title: "现场可视化看板-巡查", showMenu: true },
      //   component: () => import("@/views/inspection/index.vue"),
      // },
      {
        path: "/CaseField",
        name: "CaseField",
        // meta: { title: "现场可视化看板-案场", showMenu: true },
        meta: { title: "工地案场", showMenu: false },
        component: () => import("@/views/caseField/index.vue"),
      },
      // 菜单栏是否显示
      {
        path: "/customPreview",
        name: "customPreview", //和给后端的一样
        meta: { title: "定制案场", showMenu: false },
        component: () => import("@/views/customField/customPreview.vue"),
      },
      {
        path: "/BoardTotal",
        name: "BoardTotal",
        meta: { title: "管理端看板", showMenu: false },
        component: () => import("@/views/adminBoard/BoardTotal.vue"),
      },
      {
        path: "/ExhibitionBoard",
        name: "ExhibitionBoard",
        meta: { title: "展厅数据看板", showMenu: false },
        component: () => import("@/views/exhibitionboard/ExhibitionBoard.vue"),
      },
      {
        path: "/MapFieldboard",
        name: "MapFieldboard",
        meta: { title: "地图案场看板", showMenu: false },
        component: () => import("@/views/mapField"), //
      },
      {
        path: "/VideoRecord",
        name: "VideoRecord",
        meta: { title: "展厅直播", showMenu: false },
        component: () => import("@/views/videoRecord"),
      },
    ],
  },
  // 工地管理
  {
    path: "/Site",
    name: "Site",
    meta: { title: "工地管理", icon: "schedule" },
    component: RouteView,
    redirect: "/SiteMap",
    children: [
      {
        path: "/SiteList",
        name: "SiteList",
        meta: { title: "工地列表", showMenu: true },
        component: constantRouterComponents.SiteList,
      },
      {
        path: "/SiteMap",
        name: "SiteMap",
        meta: { title: "施工地图", showMenu: true },
        component: constantRouterComponents.SiteMap,
      },
      // {
      //   path: "/CustomMap",
      //   name: "CustomMap",
      //   meta: { title: "定制地图", showMenu: true },
      //   component: constantRouterComponents.CustomMap,
      // },

      {
        path: "/SiteSuspend",
        name: "SiteSuspend",
        meta: { title: "暂停施工", showMenu: true },
        component: constantRouterComponents.SiteSuspend,
      },
      {
        path: "/DailyList",
        name: "DailyList",
        meta: { title: "日报管理", showMenu: true },
        component: constantRouterComponents.DailyList,
      },
      {
        path: "/SampleSite",
        name: "SampleSite",
        meta: { title: "样板工地管理", showMenu: true },
        component: constantRouterComponents.SampleSite,
      },
    ],
  },
  // 人员管理
  {
    path: "/Worker",
    name: "Worker",
    meta: { title: "人员管理", icon: "team" },
    component: RouteView,
    children: [
      {
        path: "/WorkerAttend",
        name: "WorkerAttend",
        meta: { title: "人员考勤", showMenu: true },
        component: constantRouterComponents.WorkerAttend,
      },
      {
        path: "/WorkerList",
        name: "WorkerList",
        meta: { title: "人员列表", showMenu: true },
        component: constantRouterComponents.WorkerList,
      },

      {
        path: "/WorkerAttendStatistics",
        name: "WorkerAttendStatistics",
        meta: { title: "人员考勤统计", showMenu: true },
        component: constantRouterComponents.WorkerAttendStatistics,
      },
    ],
  },
  // 工地形象
  {
    path: "/image",
    name: "image",
    meta: { title: "工地形象", icon: "pic-right" },
    component: RouteView,
    children: [
      {
        path: "/AlarmEvent",
        name: "AlarmEvent",
        meta: { title: "报警事件管理", showMenu: true },
        component: constantRouterComponents.AlarmEvent,
      },
      {
        path: "/SiteNeat",
        name: "SiteNeat",
        meta: { title: "整洁管理", showMenu: true },
        component: constantRouterComponents.SiteNeat,
      },
      {
        path: "/CorrectionLog",
        name: "CorrectionLog",
        meta: { title: "纠正日志", showMenu: true },
        component: constantRouterComponents.CorrectionLog,
      },
    ],
  },
  // 数据统计
  {
    path: "/dataStatistics",
    name: "dataStatistics",
    meta: { title: "数据统计", icon: "bar-chart" },
    component: RouteView,

    children: [
      {
        path: "/DailySheet",
        name: "DailySheet",
        meta: { title: "日报", showMenu: true },
        component: constantRouterComponents.DailySheet,
      },
      {
        path: "/AttendStatistics",
        name: "AttendStatistics",
        meta: { title: "考勤统计", showMenu: true },
        component: constantRouterComponents.AttendStatistics,
      },
      {
        path: "/SiteImage",
        name: "SiteImage",
        meta: { title: "工地形象统计", showMenu: true },
        component: constantRouterComponents.SiteImage,
      },
    ],
  },

  //设备管理
  {
    path: "/device",
    name: "device",
    meta: { title: "设备管理", icon: "printer" },
    component: RouteView,
    children: [
      {
        path: "/DeviceList",
        name: "DeviceList",
        meta: { title: "设备列表", showMenu: true },
        component: constantRouterComponents.DeviceList,
      },
      {
        path: "/DeviceStock",
        name: "DeviceStock",
        meta: { title: "设备库存", showMenu: true },
        component: constantRouterComponents.DeviceStock,
      },

      {
        path: "/DeviceStatistics",
        name: "DeviceStatistics",
        meta: { title: "设备统计", showMenu: true },
        component: constantRouterComponents.DeviceStatistics,
      },
    ],
  },
  // 权限管理
  {
    path: "/role",
    name: "role",
    meta: { title: "权限管理", icon: "audit" },
    component: RouteView,
    children: [
      {
        path: "/UserList",
        name: "UserList",
        meta: { title: "用户权限", showMenu: true },
        component: constantRouterComponents.UserList,
      },
      {
        path: "/RoleList",
        name: "RoleList",
        // meta: { title: "角色权限", showMenu: true },
        meta: { title: "角色管理", showMenu: true },
        component: constantRouterComponents.RoleList,
      },
      {
        path: "/Organization",
        name: "Organization",
        meta: { title: "组织管理", showMenu: true },
        component: constantRouterComponents.Organization,
      },

      {
        path: "/PersonalizedView",
        name: "PersonalizedView",
        meta: { title: "个性化设置", showMenu: true },
        component: constantRouterComponents.PersonalizedView,
      },
      {
        path: "/MenuList",
        name: "MenuList",
        meta: { title: "页面设置", showMenu: true },
        component: constantRouterComponents.MenuList,
      },
    ],
  },
  // 交付系统
  {
    path: "/template",
    name: "template",
    // meta: { title: "模板管理", icon: "red-envelope" },
    meta: {
      title: "模板管理",
      // imgUrl: "@/assets/images/titleIcon/icon-template2.png",
      imgUrl: "template",
    },
    component: RouteView,
    children: [
      {
        path: "/MaterialCategories",
        name: "MaterialCategories",
        meta: { title: "材料类别", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.MaterialCategories,
      },
      {
        path: "/MaterialBrand",
        name: "MaterialBrand",
        meta: { title: "材料品牌", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.MaterialBrand,
      },
      {
        path: "/MaterialInfo",
        name: "MaterialInfo",
        meta: { title: "材料信息", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.MaterialInfo,
      },

      {
        path: "/ConstructProcedure",
        name: "ConstructProcedure",
        meta: { title: "施工工序", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.ConstructProcedure,
      },
      {
        path: "/ConstructType",
        name: "ConstructType",
        meta: { title: "施工项类型", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.ConstructType,
      },
      {
        path: "/ConstructionProject",
        name: "ConstructionProject",
        meta: { title: "施工项", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.ConstructionProject,
      },
      {
        path: "/PackageType",
        name: "PackageType",
        meta: { title: "套餐类别", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.PackageType,
      },
      {
        path: "/ExtraCharge",
        name: "ExtraCharge",
        meta: { title: "额外收费项", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.ExtraCharge,
      },
    ],
  },
  {
    path: "/package",
    name: "package",
    meta: { title: "套餐管理", imgUrl: "package" },
    component: RouteView,
    children: [
      {
        path: "/PackageTemplate",
        name: "PackageTemplate",
        meta: { title: "套餐列表", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.PackageTemplate,
      },
      {
        path: "/BuildPackage",
        name: "BuildPackage",
        meta: {
          title: "套餐信息",
          showMenu: false,
          memoryRouter: false,
        },
        component: constantRouterComponentsJF.BuildPackage,
      },
    ],
  },
  {
    path: "/quotation",
    name: "quotation",
    meta: { title: "报价管理", imgUrl: "quotation" },
    // meta: { title: "报价管理", icon: "red-envelope" },

    component: RouteView,
    children: [
      {
        path: "/QuotationList",
        name: "QuotationList",
        meta: { title: "报价列表", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.QuotationList,
      },
      {
        path: "/BuildQuotation",
        name: "BuildQuotation",
        meta: { title: "新建报价", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.BuildQuotation,
      },
    ],
  },
  {
    path: "/schedule",
    name: "schedule",
    meta: { title: "排期管理", imgUrl: "schedule" },
    // meta: { title: "排期管理", icon: "calendar" },
    component: RouteView,
    children: [
      {
        path: "/ScheduleList",
        name: "ScheduleList",
        meta: { title: "排期列表", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.ScheduleList,
      },
      {
        path: "/BuildSchedule",
        name: "BuildSchedule",
        meta: { title: "新建排期", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.BuildSchedule,
      },
    ],
  },

  {
    path: "/order",
    name: "order",
    meta: { title: "派单管理", imgUrl: "order" },
    // meta: { title: "派单管理", icon: "file-text" },
    component: RouteView,
    children: [
      {
        path: "/UndispatchedOrder",
        name: "UndispatchedOrder",
        meta: { title: "未派单", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.UndispatchedOrder,
      },
      {
        path: "/OrderSent",
        name: "OrderSent",
        meta: { title: "已派单", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.OrderSent,
      },
    ],
  },

  {
    path: "/material",
    name: "material",
    meta: { title: "物料管理", imgUrl: "material" },
    component: RouteView,
    children: [
      {
        path: "/WaitingOrder",
        name: "WaitingOrder",
        meta: { title: "物料下单情况", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.WaitingOrder,
      },
      {
        path: "/OrderMaterials",
        name: "OrderMaterials",
        meta: { title: "确认下单物料", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.OrderMaterials,
      },
      {
        path: "/AlreadyOrder",
        name: "AlreadyOrder",
        meta: { title: "订单列表", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.AlreadyOrder,
      },
      {
        path: "/OrderDetails",
        name: "OrderDetails",
        meta: { title: "订单详情", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.OrderDetails,
      },
      {
        path: "/TakeDelivery",
        name: "TakeDelivery",
        meta: { title: "发货查询", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.TakeDelivery,
      },
      {
        path: "/ReceiptDetails",
        name: "ReceiptDetails",
        meta: { title: "订单详情", showMenu: false, memoryRouter: false },
        component: constantRouterComponentsJF.ReceiptDetails,
      },
      {
        path: "/ChangeGoods",
        name: "ChangeGoods",
        meta: { title: "退换货", showMenu: true, memoryRouter: false },
        component: constantRouterComponentsJF.ChangeGoods,
      },

      {
        path: "/ChangeGoodsDetails",
        name: "ChangeGoodsDetails",
        meta: {
          title: "退换货详情",
          showMenu: false,
          memoryRouter: false,
          hidden: true,
        },
        component: constantRouterComponentsJF.ChangeGoodsDetails,
      },
    ],
  },
  // {
  //   path: "/stockAndOrder",
  //   name: "stockAndOrder",
  //   meta: { title: "库存与订单", icon: "pic-right" },
  //   component: RouteView,
  //   children: [
  //     {
  //       path: "/deviceStockList",
  //       name: "DeviceStockList",
  //       meta: { title: "库存管理", showMenu: true },
  //       // component: () => import("@/views/stockAndOrder/deviceStockList.vue"),
  //     },
  //   ],
  // },
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/",
    name: "BasicLayout",
    component: constantRouterComponents.BasicLayout,
    redirect: "/Login",
    children: asyncRouterMap,
  },
  {
    path: "/Login",
    name: "Login",
    component: constantRouterComponents.Login,
  },
  {
    path: "/DataView",
    name: "DataView",
    component: () => import("@/views/datav/index.vue"),
  },
  {
    path: "/PatrolView",
    name: "PatrolView",
    component: () => import("@/views/inspection/index.vue"),
  },
  {
    path: "/PatrolDetail",
    name: "PatrolView",
    component: () => import("@/views/inspection/detail.vue"),
  },
  // {
  //   path: "/Inspection",
  //   name: "Inspection",
  //   component: () => import("@/views/inspection/index.vue"),
  // },
  {
    path: "/CaseFieldView",
    name: "CaseFieldView",
    component: () => import("@/views/caseField/index.vue"),
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/404"),
  },
  {
    path: "/500",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/500"),
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
  {
    path: "/customPreviewView",
    name: "customPreviewView",
    component: () => import("@/views/customField/customPreview.vue"),
  },
  {
    path: "/BoardTotalView",
    name: "BoardTotalView",
    component: () => import("@/views/adminBoard/BoardTotal.vue"),
  },
  {
    path: "/ExhibitionBoardView",
    name: "ExhibitionBoardView",
    component: () => import("@/views/exhibitionboard/ExhibitionBoard.vue"),
  },
  {
    path: "/MapFieldboardView",
    name: "MapFieldboard",
    component: () => import("@/views/mapField"),
  },
  {
    path: "/VideoRecordView",
    name: "videoRecord",
    component: () => import("@/views/videoRecord"),
  },
];
